import {
    getDeliveryOptionsForSelectedVariant,
    GetDeliveryOptionsForSelectedVariantInput,
    getDimensionsForSelectedVariant,
    GetDimensionsForSelectedVariantInput,
    getPriceForSelectedVariant,
    getProductAvailabilitiesForSelectedVariant,
    getSelectedVariant,
    IProductInventoryInformation,
    PriceForSelectedVariantInput,
    ProductAvailabilitiesForSelectedVariantInput,
    SelectedVariantInput
} from '@msdyn365-commerce-modules/retail-actions';
// tslint:disable-next-line:no-duplicate-imports
import {
    FinitePromiseQueue, FinitePromiseQueueError, IPromiseQueue
} from '@msdyn365-commerce-modules/retail-actions';

import {
    getTelemetryObject, IModuleProps, INodeProps, ITelemetryContent
} from '@msdyn365-commerce-modules/utilities';
import { ProductDimensionFull } from '@msdyn365-commerce/commerce-entities';
import {
    ProductDeliveryOptions, ProductDimensionValue, ProductPrice, SimpleProduct
} from '@msdyn365-commerce/retail-proxy';
import { ReleasedProductType } from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceTypes.g';
import classnames from 'classnames';
import * as React from 'react';
import { IBuyboxData, IBuyboxProps } from '../..';
import { IBuyboxResources } from './buybox.props.autogenerated';
import {
    getBuyboxAddToCart,
    getBuyboxFindInStore,
    getBuyBoxInventoryLabel,
    getBuyboxKeyInPrice,
    getBuyboxProductAddToOrderTemplate,
    getBuyboxProductAddToWishlist,
    getBuyboxProductConfigure,
    getBuyboxProductDescription,
    getBuyboxProductPrice,
    getBuyboxProductQuantity,
    getBuyboxProductRating,
    getBuyboxProductTitle,
    getBuyboxShopSimilarLook,
    getQuantityLimitsMessages,
    IBuyboxAddToCartViewProps,
    IBuyboxAddToOrderTemplateViewProps,
    IBuyboxAddToWishlistViewProps,
    IBuyboxFindInStoreViewProps,
    IBuyboxKeyInPriceViewProps,
    IBuyboxProductConfigureViewProps,
    IBuyboxProductQuantityViewProps,
    IBuyboxShopSimilarLookViewProps,
    RetailDefaultOrderQuantityLimitsFeatureName
} from './components';

export declare type IBuyboxErrorHost = 'ADDTOCART' | 'FINDINSTORE' | 'WISHLIST' | 'SHOPSIMILARITEM' | 'ORDER_TEMPLATE';

export interface IErrorState {
    errorHost?: IBuyboxErrorHost;

    configureErrors: { [configureId: string]: string | undefined };
    quantityError?: string;
    customAmountError?: string;
    otherError?: string;
}

export interface IBuyboxCallbacks {
    updateQuantity(newQuantity: number): boolean;
    updateKeyInPrice(keyInPrice: number): void;
    updateErrorState(newErrorState: IErrorState): void;
    updateSelectedProduct(
        selectedProduct: Promise<SimpleProduct | null>,
        newInventory: IProductInventoryInformation | undefined,
        newPrice: ProductPrice | undefined,
        newDeliveryOptions: ProductDeliveryOptions | undefined): void;
    getDropdownName(dimensionType: number, resources: IBuyboxResources): string;
    dimensionSelectedAsync(selectedDimensionId: number, selectedDimensionValueId: string): Promise<void>;
    changeModalOpen(isModalOpen: boolean): void;
    changeUpdatingDimension(isUpdatingDimension: boolean): void;
    changeUpdatingDeliveryOptions?(isUpdatingDeliveryOptions: boolean): void;
}

export interface IBuyboxState {
    quantity: number;
    min: number | undefined;
    max: number | undefined;
    errorState: IErrorState;
    selectedProduct?: Promise<SimpleProduct | null>;
    productAvailableQuantity?: IProductInventoryInformation;
    productPrice?: ProductPrice;
    productDeliveryOptions?: ProductDeliveryOptions;
    modalOpen?: boolean;
    isUpdatingDimension?: boolean;
    isUpdatingDeliveryOptions?: boolean;
    isServiceItem?: boolean;
    isPriceKeyedIn?: boolean;
    keyInPriceAmount?: number;
    isCustomPriceSelected?: boolean;
}

export interface IBuyboxViewProps extends IBuyboxProps<IBuyboxData> {
    state: IBuyboxState;

    ModuleProps: IModuleProps;
    ProductInfoContainerProps: INodeProps;
    MediaGalleryContainerProps: INodeProps;

    callbacks: IBuyboxCallbacks;

    mediaGallery?: React.ReactNode;

    title?: React.ReactNode;
    description?: React.ReactNode;
    rating?: React.ReactNode;
    price?: React.ReactNode;
    addToOrderTemplate?: IBuyboxAddToOrderTemplateViewProps;
    addToWishlist?: IBuyboxAddToWishlistViewProps;
    max: number | undefined;

    addToCart: IBuyboxAddToCartViewProps;
    findInStore?: IBuyboxFindInStoreViewProps;
    quantity?: IBuyboxProductQuantityViewProps;
    configure?: IBuyboxProductConfigureViewProps;
    inventoryLabel?: React.ReactNode;
    shopSimilarLook?: IBuyboxShopSimilarLookViewProps;
    quantityLimitsMessages: React.ReactNode;
    telemetryContent?: ITelemetryContent;
    keyInPrice?: IBuyboxKeyInPriceViewProps;
}

/**
 * Buybox Module
 */
class Buybox extends React.PureComponent<IBuyboxProps<IBuyboxData>, IBuyboxState> {
    /**
     * A queue of tasks of processing the changes in the dimensions.
     * Limit to two processes:
     * 1 - for the current process, which is under execution at the moment.
     * 2 - next process, which will process the latest version of data.
     * @remark Enqueueing new promises will discard the previous ones (except the one which is under processing).
     */
    private dimensionUpdateQueue: IPromiseQueue<void> = new FinitePromiseQueue<void>(2);
    private dimensions: { [id: number]: string } = {};

    private buyboxCallbacks: IBuyboxCallbacks = {
        updateQuantity: (newQuantity: number): boolean => {
            const errorState = {...this.state.errorState};
            errorState.quantityError = undefined;
            errorState.otherError = undefined;

            this.setState({quantity: newQuantity, errorState: errorState});
            return true;
        },
        updateErrorState: (newErrorState: IErrorState): void => {
            this.setState({errorState: newErrorState});
        },
        updateSelectedProduct: (
            newSelectedProduct: Promise<SimpleProduct | null>,
            newInventory: IProductInventoryInformation | undefined,
            newPrice: ProductPrice | undefined,
            newDeliveryOptions: ProductDeliveryOptions | undefined
        ): void => {
            this.setState({
                selectedProduct: newSelectedProduct,
                productAvailableQuantity: newInventory,
                productDeliveryOptions: newDeliveryOptions});
            this._updatePrice(newPrice);
        },
        dimensionSelectedAsync: (selectedDimensionId: number, selectedDimensionValueId: string): Promise<void> => {
            this.dimensions[selectedDimensionId] = selectedDimensionValueId;
            return this.dimensionUpdateQueue.enqueue(() => {
                return this._updateDimensions();
            }).catch((reason: any) => { // tslint:disable-line:no-any
                // Ignore discarded processes.
                if (reason !== FinitePromiseQueueError.ProcessWasDiscardedFromTheQueue) {
                    throw reason;
                }
            });
        },
        getDropdownName:  (dimensionType: number, resources: IBuyboxResources): string => {
            return this._getDropdownName(dimensionType, resources);
        },
        changeModalOpen: (isModalOpen: boolean): void => {
            this.setState({modalOpen: isModalOpen});
        },
        changeUpdatingDimension: (isUpdatingDimension: boolean): void => {
            this.setState({isUpdatingDimension: isUpdatingDimension});
        },
        /**
         * Update isUpdatingDeliveryOptions state.
         *
         * @param isUpdatingDeliveryOptions - The status of updating delivery options.
         */
        changeUpdatingDeliveryOptions: (isUpdatingDeliveryOptions: boolean): void => {
            this.setState({ isUpdatingDeliveryOptions });
        },
        updateKeyInPrice: (customPrice: number): void => {
            // Remove custom amount error when updating the custom price
            const errorState = {...this.state.errorState};
            errorState.customAmountError = undefined;

            this.setState({isPriceKeyedIn: true, keyInPriceAmount: customPrice, errorState: errorState});
            this._updatePrice(this.state.productPrice, customPrice);
        }
    };

    private telemetryContent: ITelemetryContent;

    constructor(props: IBuyboxProps<IBuyboxData>, state: IBuyboxState) {
        super(props);
        this.state = {
            errorState: {
                configureErrors: {}
            },
            quantity: 1,
            min: undefined,
            max: undefined,
            selectedProduct: undefined,
            productPrice: undefined,
            productDeliveryOptions: undefined,

            modalOpen: false,
            isUpdatingDimension: false,
            isUpdatingDeliveryOptions: false
        };
        this.telemetryContent = getTelemetryObject(props.context.request.telemetryPageName!, props.friendlyName, props.telemetry);
    }

    public async componentDidMount(): Promise<void> {
        const {
            data: {
                product: { result: product },
                productPrice
            },
        } = this.props;

        productPrice.then(async (result) => {
            this._updatePrice(result);
        });

        if(product) {
            // check if the product is service or not by product type
            const PRODUCTASSERVICE = 2 as ReleasedProductType.Service;

            if (product.ItemTypeValue === PRODUCTASSERVICE) {
                this.setState({isServiceItem: true});
            }
            await this._updateQuantitiesInState(product);
        }
    }

    public render(): JSX.Element | null {
        const {
            slots: {
                mediaGallery,
            },
            data: {
                product: { result: product }
            },
            config: { className = '' }
        } = this.props;

        const
        {
            max
        } = this.state;

        if (!product) {
            this.props.context.telemetry.error('Product content is empty, module wont render');
            return null;
        }

        const defaultMinimumKeyInPrice = 10;
        const defaultMaximumKeyInPrice = 100;

        const viewProps: IBuyboxViewProps = {
            ...(this.props as IBuyboxProps<IBuyboxData>),
            state: this.state,
            mediaGallery: mediaGallery && mediaGallery.length > 0 ? mediaGallery[0] : undefined,
            ModuleProps: {
                moduleProps: this.props,
                className: classnames('ms-buybox', className)
            },
            ProductInfoContainerProps: {
                className: 'ms-buybox__content'
            },
            MediaGalleryContainerProps: {
                className: 'ms-buybox__media-gallery'
            },
            telemetryContent: this.telemetryContent,
            callbacks: this.buyboxCallbacks,
            title: getBuyboxProductTitle(this.props),
            description: getBuyboxProductDescription(this.props),
            configure: getBuyboxProductConfigure(this.props, this.state, this.buyboxCallbacks),
            findInStore: getBuyboxFindInStore(this.props, this.state, this.buyboxCallbacks),
            price: getBuyboxProductPrice(this.props, this.state),
            addToCart: getBuyboxAddToCart(this.props, this.state, this.buyboxCallbacks, defaultMinimumKeyInPrice, defaultMaximumKeyInPrice),
            addToOrderTemplate: getBuyboxProductAddToOrderTemplate(this.props, this.state, this.buyboxCallbacks),
            addToWishlist: getBuyboxProductAddToWishlist(this.props, this.state, this.buyboxCallbacks),
            rating: !this.props.context.app.config.hideRating && getBuyboxProductRating(this.props),
            quantity: product.IsGiftCard ? undefined : getBuyboxProductQuantity(this.props, this.state, this.buyboxCallbacks),
            inventoryLabel: getBuyBoxInventoryLabel(this.props),
            shopSimilarLook: this.props.config.enableShopSimilarLooks && !product.IsGiftCard ? getBuyboxShopSimilarLook(this.props, this.state, this.buyboxCallbacks) : undefined,
            keyInPrice: this.props.config.enableKeyInPrice && this.state.isCustomPriceSelected?getBuyboxKeyInPrice(this.props, this.state, this.buyboxCallbacks):undefined,
            quantityLimitsMessages: getQuantityLimitsMessages(this.props, this.state),
            max: max
        };

        return this.props.renderView(viewProps) as React.ReactElement;
    }

    private _updatePrice(
        newPrice: ProductPrice | undefined,
        customPrice: number | undefined = this.state.keyInPriceAmount): void {

        if (this.state.isCustomPriceSelected && newPrice) {
            newPrice.CustomerContextualPrice = customPrice;
        }
        this.setState({productPrice: newPrice});
    }

    // tslint:disable-next-line:max-func-body-length
    private _updateDimensions = async (): Promise<void> => {
        const {
            data: {
                product: {result: product },
                productDimensions: {result: productDimensions },
            },
            context: {
                actionContext,
                request: {
                    apiSettings: {
                        channelId
                    }
                }
            }
        } = this.props;

        if (!product || !productDimensions) {
            return;
        }

        const dimensionsToUpdate: { [id: number]: string } = {...this.dimensions};
        this.setState({isUpdatingDimension: true, isUpdatingDeliveryOptions: true });

        // Step 1: Update state to indicate which dimensions are selected
        if (this.state.errorState.otherError) {
            const clearOtherErrorState = {...this.state.errorState};
            clearOtherErrorState.otherError = undefined;
            this.setState({errorState: clearOtherErrorState});
        }

        // Step 2: Clear any errors indicating the dimension wasn't selected
        for (const key of Object.keys(dimensionsToUpdate)) {
            if (this.state.errorState.configureErrors[key]) {
                const errorState = {...this.state.errorState};
                errorState.configureErrors[key] = undefined;

                this.setState({errorState: errorState});
            }
        }

        // Step 3, Build the actually selected dimensions, prioritizing the information in state
        // over the information in data
        const mappedDimensions = productDimensions.map(dimension => {
            return {
                DimensionTypeValue: dimension.DimensionTypeValue,
                DimensionValue: this._updateDimensionValue(dimension, dimensionsToUpdate[dimension.DimensionTypeValue]) || dimension.DimensionValue,
                ExtensionProperties: dimension.ExtensionProperties
            };
        }).filter(dimension => {
            return dimension && dimension.DimensionValue;
        });
        // Step 4. Use these dimensions hydrate the product. Wrap this in a promise
        // so that places like add to cart can await it
        const selectedProduct = new Promise<SimpleProduct | null>(async (resolve, reject) => {
            const newProduct = (await getSelectedVariant(
                new SelectedVariantInput(
                    product.MasterProductId ? product.MasterProductId : product.RecordId,
                    channelId,
                    mappedDimensions
                ),
                actionContext
            ));
            if (newProduct) {
                await getDimensionsForSelectedVariant(
                    new GetDimensionsForSelectedVariantInput(
                        newProduct.MasterProductId ? newProduct.MasterProductId : newProduct.RecordId,
                        channelId,
                        mappedDimensions
                    ),
                    actionContext
                );
            }

            resolve(newProduct);
        });
        this.setState({selectedProduct: selectedProduct});
        const variantProduct = await selectedProduct;

        if(variantProduct) {
            // Step 5. Use these dimensions hydrate the inventory. Wrap this in a promise
            // so that places like add to cart can await it
            const newAvailableQuantity = await getProductAvailabilitiesForSelectedVariant(
                new ProductAvailabilitiesForSelectedVariantInput(
                    variantProduct.RecordId,
                    channelId
                ),
                actionContext
            );

            const isCustompriceSelected = variantProduct && variantProduct.Dimensions && variantProduct.Dimensions.find(dimension => dimension.DimensionTypeValue === 4 && dimension.DimensionValue && dimension.DimensionValue.Value === 'Custom');
            if (isCustompriceSelected) {
                this.setState({isCustomPriceSelected: true});
            } else {
                // Remove custom amount error when unselect the custom amount
                const errorState = {...this.state.errorState};
                errorState.customAmountError = undefined;

                this.setState({isCustomPriceSelected: false, isPriceKeyedIn: false, errorState: errorState});
            }

            if(newAvailableQuantity && newAvailableQuantity.length) {
                this.setState({productAvailableQuantity: newAvailableQuantity[0]});
            } else {
                this.setState({productAvailableQuantity: undefined});
            }

            // Step 6. Use these dimensions hydrate the product price.
            const newPrice = await getPriceForSelectedVariant(
                new PriceForSelectedVariantInput(
                    variantProduct.RecordId,
                    channelId
                ),
                actionContext
            );

            if (newPrice) {
                this._updatePrice(newPrice);
            }

            const RetailMulitplePickupMFeatureState = this.props.data.featureState.result?.find(featureState => featureState.Name === 'Dynamics.AX.Application.RetailMultiplePickupDeliveryModeFeature');
            // Step 7. Use these dimensions hydrate the product delivery options.
            const newDeliveryOptions = await getDeliveryOptionsForSelectedVariant(
                new GetDeliveryOptionsForSelectedVariantInput(
                    variantProduct.RecordId,
                    channelId,
                    undefined,
                    undefined,
                    RetailMulitplePickupMFeatureState?.IsEnabled
                ),
                actionContext
            );

            if(newDeliveryOptions) {
                this.setState({productDeliveryOptions: newDeliveryOptions});
            }

            await this._updateQuantitiesInState(variantProduct);
        }
    }

    private async _updateQuantitiesInState(product: SimpleProduct): Promise<void> {
        const isOrderQuantityLimitsFeatureEnabled = await this._isOrderQuantityLimitsFeatureEnabled();
        if (isOrderQuantityLimitsFeatureEnabled && product) {
            this.setState({
                quantity: product.Behavior?.DefaultQuantity || 1,
                min: product.Behavior?.MinimumQuantity || 1,
                max: (product.Behavior?.MaximumQuantity && product.Behavior?.MaximumQuantity > 0)
                    ? product.Behavior?.MaximumQuantity
                    : Number.MAX_VALUE,
            });
        } else {
            this.setState({
                min: 1,
                max: this.props.context.app.config.maxQuantityForCartLineItem || 10,
            });
        }
    }

    private _updateDimensionValue = (productDimensionFull: ProductDimensionFull, newValueId: string | undefined): ProductDimensionValue | undefined => {
        if (newValueId && productDimensionFull.DimensionValues) {
            return productDimensionFull.DimensionValues.find(dimension => dimension.RecordId === +newValueId);
        }

        return undefined;
    };

    private _getDropdownName = (dimensionType: number, resources: IBuyboxResources): string => {
        const isGiftCard = this.props.data.product.result?.IsGiftCard;

        switch (dimensionType) {
            case 1: // ProductDimensionType.Color
                return resources.productDimensionTypeColor;
            case 2: // ProductDimensionType.Configuration
                return resources.productDimensionTypeConfiguration;
            case 3: // ProductDimensionType.Size
                return resources.productDimensionTypeSize;
            case 4: // ProductDimensionType.Style
                return isGiftCard ? resources.productDimensionTypeAmount : resources.productDimensionTypeStyle;
            default:
                return '';
        }
    };

    private async _isOrderQuantityLimitsFeatureEnabled(): Promise<boolean> {
        const defaultOrderQuantityLimitsFeatureConfig = this.props.context?.request?.app?.platform?.enableDefaultOrderQuantityLimits;
        if (defaultOrderQuantityLimitsFeatureConfig === 'none') {
            return false;
        }

        const featureStatuses = await this.props.data.featureState;
        const isFeatureEnabledInHq = featureStatuses?.find(featureState => featureState.Name === RetailDefaultOrderQuantityLimitsFeatureName)?.IsEnabled;
        if (!isFeatureEnabledInHq) {
            return false;
        }

        if (defaultOrderQuantityLimitsFeatureConfig === 'all') {
            return true;
        }
        let customerInfo;
        try {
            customerInfo = await this.props.data.customerInformation;
        } catch(error) {
            this.props.telemetry.information(error);
            this.props.telemetry.debug('Unable to receive Customer Information. May be user is not authorized');
            return false;
        }

        return customerInfo &&
            ((defaultOrderQuantityLimitsFeatureConfig === 'b2b' && customerInfo.IsB2b) ||
            (defaultOrderQuantityLimitsFeatureConfig === 'b2c' && !customerInfo.IsB2b));
    }
}

export default Buybox;
