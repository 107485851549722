import React from 'react';

import { EnumExtensions } from '@msdyn365-commerce-modules/retail-actions';
import { Button, Popover } from '@msdyn365-commerce-modules/utilities';
import { IComponentProps, msdyn365Commerce } from '@msdyn365-commerce/core';
import { Customer } from '@msdyn365-commerce/retail-proxy';

export interface IOrderHistoryFilterResources {
    orderHistoryFilterYourOrderHistory: string;
    orderHistoryFilterOrganizationWide: string;
}

export interface IOrderHistoryFilterData {
    customerInformation: Customer;
}

export interface IOrderHistoryFilterProps extends IComponentProps<IOrderHistoryFilterData> {
    className: string;

    resources: IOrderHistoryFilterResources;

    currentState: OrderHistoryFilterState;
    onFilterStateChanged(state: OrderHistoryFilterState): void;
}

export enum OrderHistoryFilterState {
    CurrentUser,
    OrganizationWide
}

const getOrderHistoryFilterStateResources = (filterState: OrderHistoryFilterState, resources: IOrderHistoryFilterResources): string => {
    switch (filterState) {
        case OrderHistoryFilterState.CurrentUser:
            return resources.orderHistoryFilterYourOrderHistory;
        case OrderHistoryFilterState.OrganizationWide:
            return resources.orderHistoryFilterOrganizationWide;
        default:
            throw new Error('Failed to retrieve resource description for unknown order history filter state.');
    }
};

const renderFilterStateItem = (
    filterState: OrderHistoryFilterState,
    resources: IOrderHistoryFilterResources,
    className: string,
    onStateClick: (filterState: OrderHistoryFilterState) => void): React.ReactNode => {

    const stateStringRepresentation = OrderHistoryFilterState[filterState];

    const onClick = () => {
        return onStateClick(filterState);
    };

    return (
        <a role='button' className={`${className}__item__${stateStringRepresentation}`} onClick={onClick}>
            {getOrderHistoryFilterStateResources(filterState, resources)}
        </a>
    );
};

const OrderHistoryFilter: React.FC<IOrderHistoryFilterProps> = (props: IOrderHistoryFilterProps) => {
    // Current version of filter doesn't include functionality for non b2b admins.
    if (!props.data.customerInformation.IsB2bAdmin) {
        return null;
    }

    const popoverRef = React.createRef<HTMLButtonElement>();
    const [popoverState, setPopoverState] = React.useState(false);
    const togglePopover = () => {
        setPopoverState(!popoverState);
    };

    const [filterState, setFilterState] = React.useState(props.currentState);

    const onFilterStateClick = (clickedState: OrderHistoryFilterState) => {
        setFilterState(clickedState);
        setPopoverState(false);
        props.onFilterStateChanged(clickedState);
    };

    return (
        <div className={props.className}>
            <Button
                className={`${props.className}__expand-filter-button`}
                innerRef={popoverRef}
                aria-expanded={popoverState}
                aria-describedby={props.className}
                onClick={togglePopover}
            >
                {getOrderHistoryFilterStateResources(filterState, props.resources)}
            </Button>
            <Popover
                id={props.className}
                placement={'bottom-end'}
                isOpen={popoverState}
                target={popoverRef}
                toggle={togglePopover}
            >
                {
                    EnumExtensions.getEnumValues<OrderHistoryFilterState>(OrderHistoryFilterState).map(state => {
                        return renderFilterStateItem(state, props.resources, props.className, onFilterStateClick);
                    })
                }
            </Popover>
        </div>
    );
};

// @ts-ignore
export const OrderHistoryFilterComponent: React.FunctionComponent<IOrderHistoryFilterProps> = msdyn365Commerce.createComponent<IOrderHistoryFilterProps>(
    'OrderHistoryFilterComponent',
    { component: OrderHistoryFilter }
);