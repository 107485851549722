import { IGiftCardExtend } from '@msdyn365-commerce/global-state';
import { Address, GiftCard, TenderLine, TokenizedPaymentCard } from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceTypes.g';
import { action, computed, observable } from 'mobx';

/**
 * State information related to what is needed to succesfully perform a checkout
 */
export class CheckoutState {
    @observable private _tokenizedPaymentCard?: TokenizedPaymentCard;
    @computed public get tokenizedPaymentCard(): Readonly<TokenizedPaymentCard | undefined> {
        return this._tokenizedPaymentCard;
    }

    @observable private _tenderLine?: TenderLine;
    @computed public get tenderLine(): Readonly<TenderLine | undefined> {
        return this._tenderLine;
    }

    @observable private _billingAddress?: Address;
    @computed public get billingAddress(): Readonly<Address | undefined> {
        return this._billingAddress;
    }

    @observable private _shippingAddress?: Address;
    @computed public get shippingAddress(): Readonly<Address | undefined> {
        return this._shippingAddress;
    }

    @observable private _cardPrefix?: string;
    @computed public get cardPrefix(): Readonly<string | undefined> {
        return this._cardPrefix;
    }

    @observable private _giftCards: GiftCard[] = [];
    @computed public get giftCards(): Readonly<GiftCard[]> {
        return this._giftCards;
    }

    @observable private _giftCardExtends: IGiftCardExtend[] = [];
    @computed public get giftCardExtends(): Readonly<IGiftCardExtend[]> {
        return this._giftCardExtends;
    }

    @observable private _loyaltyCardNumber?: string;
    @computed public get loyaltyCardNumber(): Readonly<string | undefined> {
        return this._loyaltyCardNumber;
    }

    @observable private _loyaltyAmount: number = 0;
    @computed public get loyaltyAmount(): Readonly<number> {
        return this._loyaltyAmount;
    }

    @observable private _guestCheckoutEmail: string = '';
    @computed public get guestCheckoutEmail(): Readonly<string> {
        return this._guestCheckoutEmail;
    }

    @observable private _isTermsAndConditionAccepted: boolean = false;
    @computed public get isTermsAndConditionAccepted(): Readonly<boolean> {
        return this._isTermsAndConditionAccepted;
    }

    @observable private _customerAccountAmount: number = 0;
    @computed public get customerAccountAmount(): Readonly<number> {
        return this._customerAccountAmount;
    }

    @action
    public updateTokenizedPaymentCard(newTokenizedPaymentCard: TokenizedPaymentCard): void {
        this._tokenizedPaymentCard = newTokenizedPaymentCard;
    }

    @action
    public updateTenderLine(newTenderLine?: TenderLine): void {
        this._tenderLine = newTenderLine;
    }

    @action
    public updateShippingAddress(newShippingAddress: Address): void {
        this._shippingAddress = newShippingAddress;
    }

    @action
    public updateBillingAddress(newBillingAddress: Address): void {
        this._billingAddress = newBillingAddress;
    }

    @action
    public updateCardPrefix(newCardPrefix: string): void {
        this._cardPrefix = newCardPrefix;
    }

    @action
    public removeGiftCard(giftCardNumber: string): void {
        this._giftCards = this._giftCards.filter(giftCard => giftCard.Id !== giftCardNumber);
    }

    @action
    public removeGiftCardExtend(giftCardNumber: string): void {
        this._giftCardExtends = this._giftCardExtends.filter(giftCard => giftCard.Id !== giftCardNumber);
    }

    @action
    public addGiftCard(giftCard: GiftCard): void {
        this._giftCards = [giftCard, ...this._giftCards];
    }

    @action
    public addGiftCardExtend(giftCard: IGiftCardExtend): void {
        this._giftCardExtends = [giftCard, ...this._giftCards];
    }

    @action
    public updateLoyaltyCardNumber(newLoyaltyCardNumber: string): void {
        this._loyaltyCardNumber = newLoyaltyCardNumber;
    }

    @action
    public updateLoyaltyAmount(newAmount: number): void {
        this._loyaltyAmount = newAmount;
    }

    @action
    public updateGuestCheckoutEmail(newGuestCheckoutEmail: string): void {
        this._guestCheckoutEmail = newGuestCheckoutEmail;
    }

    @action
    public updateTermsAndConditionsAcceptance(newIsTermsAndConditionAccepted: boolean): void {
        this._isTermsAndConditionAccepted = newIsTermsAndConditionAccepted;
    }

    @action
    public updateCustomerAccountAmount(newAmount: number): void {
        this._customerAccountAmount = newAmount;
    }
}
