import { buildCacheKey, getOrganizationWideOrderHistory, GetOrganizationWideOrderHistoryInput, getSimpleProducts, ProductInput } from '@msdyn365-commerce-modules/retail-actions';
import {
    CacheType,
    createObservableDataAction,
    IAction,
    IActionContext,
    IActionInput,
    ICommerceApiSettings
} from '@msdyn365-commerce/core';
import { IQueryResultSettings } from '@msdyn365-commerce/retail-proxy';
import { getOrderShipmentsHistoryAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/CustomersDataActions.g';
import { OrderShipments, SimpleProduct } from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceTypes.g';
import { OrderHistoryFilterState } from '../components/order-history-filter';

export interface IPaging {
    top: number;
    skip: number;
}

export interface IOrderHistory {
    salesOrders: OrderShipments[];
    products: SimpleProduct[];
}

/**
 * Calls the Retail API and returns the products
 */
const getOrderShipmentHistory = (paging: IPaging) => async (ctx: IActionContext): Promise<OrderShipments[]> => {
    return getOrderShipmentsHistoryAsync(
        {
            callerContext: ctx,
            queryResultSettings: {
                Paging: {
                    Top: paging.top,
                    Skip: paging.skip
                }
            }
        },
        ''
    );
};

/**
 * Calls the Retail API and returns the products
 */
const getOrganizationWideOrderShipmentHistory = (paging: IPaging) => async (context: IActionContext): Promise<OrderShipments[]> => {
    const queryResultSetting: IQueryResultSettings = {
        Paging: {
            Top: paging.top,
            Skip: paging.skip
        }
    };

    return getOrganizationWideOrderHistory(new GetOrganizationWideOrderHistoryInput(queryResultSetting), context);
};

/**
 * Calls the Retail API and returns the products
 */
const getProducts = (productIds: number[] = [], channelId?: number) => (ctx: IActionContext): Promise<SimpleProduct[]> => {
    const productInputs = productIds.map(productId => new ProductInput(productId, ctx.requestContext.apiSettings, channelId));
    return getSimpleProducts(productInputs, ctx);
};

/**
 *  Action input
 */
export class GetSalesOrderHistoryWithHydrationsInput implements IActionInput {
    public paging: IPaging;
    public filterState: OrderHistoryFilterState;
    private apiSettings: ICommerceApiSettings;

    constructor(paging: IPaging, apiSettings: ICommerceApiSettings, filterState?: OrderHistoryFilterState) {
        this.apiSettings = apiSettings;
        this.paging = paging;
        this.filterState = filterState || OrderHistoryFilterState.CurrentUser;
    }

    public getCacheKey = () => buildCacheKey(`OrderHistory`, this.apiSettings);
    public getCacheObjectType = () => `OrderHistory`;
    public dataCacheType = (): CacheType => 'request';
}

/**
 * Splits product ids from the given orders into lists by their channel id.
 * @param {OrderShipments[]} salesOrders Orders with the products.
 * @param {number} currentChannelId Channel id to use by default if no channel id is provided for a product.
 * @returns {{ [x: number]: number[] }} A dictionary where the key is a channel id,
 * and the value is a list of product ids in which all items correspond to the key channel id.
 * @remark The list of product ids is always non-empty.
 */
const splitProductsByChannelId = (
    salesOrders: OrderShipments[],
    currentChannelId: number): { [x: number]: number[] } => {

    const productIdsByChannel: { [x: number]: number[] } = {};

    salesOrders.forEach(salesOrder => salesOrder?.SalesLines?.forEach(line => {
        const orderProductId = line.ProductId || 0;
        const orderChannelId = salesOrder.ChannelId || currentChannelId;
        if (!productIdsByChannel[orderChannelId]) {
            productIdsByChannel[orderChannelId] = [];
        }
        productIdsByChannel[orderChannelId].push(orderProductId);
    }));

    return productIdsByChannel;
};

/**
 * Get sales order with hydrations action
 */
export async function getSalesOrderHistoryWithHydrationsAction(
    input: GetSalesOrderHistoryWithHydrationsInput,
    context: IActionContext
): Promise<IOrderHistory> {
    if (!context) {
        throw new Error('getSalesOrderWithHydrationsAction - Action context cannot be null/undefined');
    }
    const channelId = context.requestContext.apiSettings.channelId;
    let salesOrders: OrderShipments[] = [];

    switch (input.filterState) {
        case OrderHistoryFilterState.CurrentUser:
            salesOrders = await getOrderShipmentHistory(input.paging)(context);
            break;
        case OrderHistoryFilterState.OrganizationWide:
            salesOrders = await getOrganizationWideOrderShipmentHistory(input.paging)(context);
            break;
        default:
            throw new Error('getSalesOrderWithHydrationsAction - Invalid OrderHistoryFilterState passed');
    }

    if (!salesOrders || !salesOrders.length) {
        return {
            salesOrders: [],
            products: []
        };
    }

    // Splits the data by channel ids so that the products from different channels can be processed separately.
    const productIdsByChannelId = splitProductsByChannelId(salesOrders, channelId);

    // Promise that retrieves information about the products for each channel id.
    // Note, the list of product ids should not be empty
    // as it will generate an empty request which will force the promise to fail.
    const getProductsPromise = Object.entries(productIdsByChannelId).map(
        ([entryChannelId, entryProductIdsList]) => {
            const getProductsCall = getProducts(entryProductIdsList, Number(entryChannelId));
            return getProductsCall(context);
        });

    return Promise.all(getProductsPromise).then(
        (productList): IOrderHistory => {
            const products = productList.reduce((memo, list) => {
                return [...memo, ...list];
            }, []); // tslint:disable-line:align

            return {
                salesOrders,
                products
            };
        }).catch(error => {
            context.telemetry.exception(error);
            context.telemetry.debug('Failed to get products');
            throw error;
        });
}

export default createObservableDataAction({
    id: '@msdyn365-commerce-modules/order-management/order-history/get-order-shipment-history',
    action: <IAction<IOrderHistory>>getSalesOrderHistoryWithHydrationsAction
});